.fret {
  background: #d1bda6;
  position: relative;
  border-right: 1px solid black;
  opacity: 0.5;
}

.fret.fret-selected {
  background: #845924;
  opacity: 1;
}

.fret-open.fret-selected {
  background: #84592470;
  opacity: 1;
}

.fret.fret-hovered {
  background: #f8b25d;
  opacity: 1;
}

.fret-open.fret-hovered {
  background: #f8b25d30;
  opacity: 1;
}

.fretnum {
  padding: 0;
  right: 15px;
  text-align: center;
  font-weight: bold;
  /* cursor: pointer; */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.string {
  position: absolute;
  height: 50%;
  width: 100%;
  border-bottom: 2px solid #afafaf;
  z-index: 1;
}

.shadow {
  -moz-box-shadow: 3px 3px 4px #444;
  -webkit-box-shadow: 3px 3px 4px #444;
  box-shadow: 3px 3px 4px #444;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444')";
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444');
}

.guitar {
  z-index: 1;
  margin: auto;
}

.on {
  /* background: black; */
  color: white;
}

.off {
  background: none;
}

.open {
  background: white;
  /* color: black; */
}

.on-selected-root {
  color: white;
  /* background: red; */
}

.selector {
  z-index: 10;
  /* cursor: move; */
  border: 5px solid greenyellow;
  padding: 0px;
  margin-left: -15px;
}

.circleBase {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin: auto;
  padding: 0px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  z-index: 3;
  position: absolute;
  line-height: 26px;
  margin-left: 10px;
}

.padding0 {
  padding: 0;
}

.text-bold {
  font-weight: bold;
}

.top-toolbar,
.bot-toolbar {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -15px;
}

.p-indent-50 {
  text-indent: 50px;
}

.weirdInput {
  line-height: 30px;
  width: 7.5rem;
  /* margin-left: 13px; */
  padding-left: 8px;
}

.bpmLabel {
  position: absolute;
  right: 6px;
  height: 100%;
  text-align: center;
  /* display: flex; */
  margin: auto;
  line-height: 36px;
}

.js-guitar {
  user-select: none;
}

body {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: -webkit-fill-available;
}
